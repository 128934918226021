<template>
  <div
    class="organism popular-neighborhoods pt-8 sm:pt-[76px] pb-[104px] px-8"
    :style="'background-color:' + organism.backgroundColor"
  >
    <h2
      class="font-cg text-gray-800 text-3xl sm:text-[46 px] text-center max-w-xl mx-auto font-semibold mb-12 leading-none"
    >
      You may also be interested in these communities:
    </h2>
    <ul class="popular-item-group">
      <li v-for="item in visibleItems">
        <MoleculesOverviewItem :item-raw-data="item" :plans="organism.plans" />
      </li>
    </ul>
  </div>
</template>

<style>
.popular-neighborhoods {
  .popular-item-group {
    @apply flex gap-8 flex-wrap max-w-screen-xl mx-auto justify-center items-start;
  }

  article {
    @apply max-w-md m-0 p-0;

    header {
      @apply bg-white drop-shadow-lg m-0 gap-0 sm:gap-2 xl:gap-7 sm:h-[180px] md:h-auto h-auto overflow-hidden;

      figure {
        @apply h-full;

        img {
          @apply overflow-hidden sm:h-full md:h-auto h-auto;
        }
      }

      .item-text {
        @apply px-[18px] xl:pl-0 pl-4 self-start;
      }
    }
  }

  .explore-button {
    @apply hidden;
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

const visibleItems = computed(() => {
  return props.organism.items.filter(item => !item.neighborhood.hideCommunity);
});
</script>
